import {
  InvoiceDoc,
  PaymentItemDoc,
  PaymentStatusCode,
  paymentMethods,
  paymentStatusCodeKrMap,
  paymentVendors,
} from '@gooduncles/gu-app-schema';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { message, notification } from 'antd';
import { Timestamp } from 'firebase/firestore';
import { FC, useMemo } from 'react';
import { cellStyleForPaymentMethod, getHyosungProductName, sumPaymentItems } from 'src/utils/payment-util';

import { formatDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import { updateInvoice } from 'src/lib/4/firebase-short-cut';
import { calculatePreTaxPrice } from 'src/lib/5/order-util';
import { getCellClassForPaymentStatus, numberFormatter, onCellValueChangedWithUpdate } from 'src/lib/6/ag-grid-util';

import SettlementByStoreModal from '../SettlementByStoreModal/SettlementByStoreModal';

export type NewUnsettledInvoiceTableRowData = InvoiceDoc & {
  storeNickname: string | null;
  paymentItems: PaymentItemDoc[];
};

interface NewUnsettledInvoiceTableProps {
  rowData: NewUnsettledInvoiceTableRowData[];
  setSelectedRows: React.Dispatch<React.SetStateAction<NewUnsettledInvoiceTableRowData[]>>;
}

const dateFormatter = ({ value }: { value: string | null }) => (value ? formatDate(value, 'yyyy-MM-dd HH:mm:ss') : '');
const onCellValueChanged = onCellValueChangedWithUpdate<NewUnsettledInvoiceTableRowData>('청구서(관리)');

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

const NewUnsettledInvoiceTable: FC<NewUnsettledInvoiceTableProps> = ({ rowData, setSelectedRows }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const columnDefs: (ColDef<NewUnsettledInvoiceTableRowData> | ColGroupDef<NewUnsettledInvoiceTableRowData>)[] =
    useMemo(
      () => [
        {
          headerName: '매장명',
          field: 'storeNickname',
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
        },
        { field: '_id', hide: true },
        { headerName: '매장Id', field: 'storeId', hide: true },
        { headerName: '매장코드', field: 'storeCode', hide: true },
        {
          headerName: '이전 정산',
          pinned: 'left',
          width: 130,
          cellRenderer: (params: any) => {
            const data = params.data as NewUnsettledInvoiceTableRowData;
            if (data.storeId) return <SettlementByStoreModal storeId={data.storeId} />;
          },
        },
        {
          headerName: '생성일',
          hide: true,
          field: '_timeCreate',
          valueGetter: (params: any) => {
            const data = params.data as NewUnsettledInvoiceTableRowData;
            const timestamp = data._timeCreate as Timestamp;
            return timestamp ? formatDate(timestamp.toDate(), 'yyyy-MM-dd') : '';
          },
          width: 150,
        },
        {
          headerName: '결제처',
          field: 'paymentInfo.paymentVendor',
          width: 100,
          editable: true,
          cellEditorPopup: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: (params: any) => {
            const paymentinfo: InvoiceDoc['paymentInfo'] = params.data.paymentInfo;
            return {
              values: paymentinfo.contractNumber
                ? paymentVendors
                : paymentVendors.filter((vendor) => vendor !== '효성CMS'),
            };
          },
          onCellValueChanged: (params) => onCellValueChanged(params, 'invoice', params.data._id),
        },
        {
          headerName: '결제방식',
          field: 'paymentInfo.paymentMethod',
          width: 100,
          editable: true,
          cellEditorPopup: true,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: (params: any) => {
            const paymentinfo: InvoiceDoc['paymentInfo'] = params.data.paymentInfo;
            return {
              values: paymentinfo.contractNumber
                ? paymentMethods
                : paymentMethods.filter((method) => !['CMS', '실시간CMS'].includes(method)),
            };
          },
          cellStyle: cellStyleForPaymentMethod,
          onCellValueChanged: (params) => onCellValueChanged(params, 'invoice', params.data._id),
        },
        { headerName: 'ⓗ회원번호', field: 'paymentInfo.storeCode', width: 110 },
        { headerName: 'ⓗ계약번호', field: 'paymentInfo.contractNumber', width: 110 },
        { headerName: 'ⓗ회원명', field: 'paymentInfo.name', width: 100 },
        {
          headerName: 'ⓗ상품',
          valueGetter: (params: any) => {
            const data = params.data as NewUnsettledInvoiceTableRowData;
            if (data.paymentInfo.paymentVendor === '효성CMS') {
              return getHyosungProductName(data.paymentInfo.paymentMethod, data);
            }
            return '';
          },
          cellStyle: (params: any) => {
            if (params.value.includes('카드')) return { color: 'var(--blue400)' };
          },
          width: 130,
        },
        {
          headerName: '정산금액',
          cellRenderer: 'agGroupCellRenderer',
          width: 140,
          type: 'numericColumn',
          valueGetter: (params: any) => {
            const { paymentItems } = params.data as NewUnsettledInvoiceTableRowData;
            const { amount } = sumPaymentItems(paymentItems);
            return amount;
          },
          valueFormatter: numberFormatter,
          cellStyle: (params: any) => {
            const { invoiceAmount } = params.data as NewUnsettledInvoiceTableRowData;
            const value = params.value as number;
            if (invoiceAmount > 0) {
              if (value === invoiceAmount)
                return {
                  backgroundColor: 'var(--green200)',
                };
              if (value < invoiceAmount)
                return {
                  backgroundColor: 'var(--yellow200)',
                };
              if (value > invoiceAmount || value === 0)
                return {
                  backgroundColor: 'var(--red100)',
                };
            }
          },
        },
        {
          headerName: '청구금액',
          field: 'invoiceAmount',
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
        },
        {
          headerName: '이전 미납금',
          field: 'prevUnpaidAmount',
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
          cellStyle: (params: any) => {
            const value = params.value as number;
            if (value > 0)
              return {
                color: 'var(--red600)',
              };
          },
        },
        {
          headerName: '면세',
          field: 'taxExemptAmount',
          width: 120,
          type: 'numericColumn',
          editable: true,
          valueSetter: (params) => {
            const newValue = Number(params.newValue);
            if (isNaN(newValue)) {
              notification.error({
                message: '면세금액 수정 실패',
                description: '숫자만 입력해주세요.',
              });
              return false;
            }
            params.data.taxExemptAmount = newValue;
            return true;
          },
          onCellValueChanged: async (params) => {
            const oldValue = params.oldValue;
            const newValue = params.newValue;
            const invoice = params.data;
            if (oldValue === newValue) return;
            // 청구금액 - 면세금액 = 과세금액
            const newTaxableAmount = invoice.invoiceAmount - newValue;
            try {
              const key = invoice._id;
              messageApi.open({
                key,
                type: 'loading',
                content: '면세 & 과세 금액 수정 중...',
                duration: 0,
              });
              await updateInvoice(invoice._id, { taxExemptAmount: newValue, taxableAmount: newTaxableAmount });
              messageApi.open({
                key,
                type: 'success',
                content: `면세 & 과세 금액 수정 완료: 면세 ${newValue}원, 과세 ${newTaxableAmount}원`,
                duration: 2,
              });
            } catch (error) {
              console.error(error);
              const description = errorObjectToString(error);
              notification.error({
                message: '면세 & 과세 금액 수정 실패',
                description,
              });
            }
          },
          valueFormatter: numberFormatter,
        },
        {
          headerName: '과세',
          field: 'taxableAmount',
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
        },
        {
          headerName: '공급가액',
          field: 'supplyPrice',
          valueGetter: (params: any) => {
            const { price } = calculatePreTaxPrice(params.data.taxableAmount ?? 0);
            return price;
          },
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
        },
        {
          headerName: '부가세',
          field: 'tax',
          valueGetter: (params: any) => {
            const { tax } = calculatePreTaxPrice(params.data.taxableAmount ?? 0);
            return tax;
          },
          valueFormatter: numberFormatter,
          width: 120,
          type: 'numericColumn',
        },
      ],
      [messageApi]
    );

  /**
   * 정산금액의 상세 내역을 보여주기 위한 렌더러 설정
   */
  const detailCellRendererParams = useMemo(() => {
    const detailColumnDefs: (ColDef<PaymentItemDoc> | ColGroupDef<PaymentItemDoc>)[] = [
      {
        field: 'transactionDate',
        headerName: '결제일시',
        valueFormatter: dateFormatter,
      },
      {
        field: 'payerInfo',
        headerName: '결제자',
      },
      {
        field: 'paymentStatus',
        headerName: '결제상태',
        width: 100,
        valueFormatter: ({ value }) => paymentStatusCodeKrMap[value as unknown as PaymentStatusCode],
        cellClass: getCellClassForPaymentStatus,
        filterParams: {
          valueFormatter: (params: any) => paymentStatusCodeKrMap[params.value as unknown as PaymentStatusCode],
        },
      },
      {
        field: 'paymentMethod',
        headerName: '결제수단',
        cellStyle: cellStyleForPaymentMethod,
        width: 100,
      },
      {
        field: 'paymentVendor',
        headerName: '출처',
        width: 100,
      },
      {
        field: 'amount',
        headerName: '결제금액',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'supplyPrice',
        headerName: '공급가액',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'tax',
        headerName: '부가세',
        width: 120,
        valueFormatter: numberFormatter,
      },
      {
        field: 'extraInfo',
        headerName: '기타정보',
      },
      {
        field: 'settledAt',
        headerName: '정산시각',
        valueFormatter: dateFormatter,
      },
      {
        field: 'settlementResultId',
        headerName: '정산결과',
        width: 100,
        valueFormatter: ({ value }) => (value ? '정산' : '미정산'),
      },
      {
        field: 'createdBy',
        headerName: '생성자',
      },
    ];

    return {
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
      },
      getDetailRowData: (params: any) => {
        const paymentItems = params.data.paymentItems as PaymentItemDoc[];
        params.successCallback(paymentItems);
      },
    };
  }, []);

  const isRowMaster = (data: NewUnsettledInvoiceTableRowData) => data.paymentItems.length > 0;

  return (
    <div className='ag-theme-alpine height100 width100'>
      {contextHolder}
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        // detailRow
        isRowMaster={isRowMaster}
        keepDetailRows={true}
        masterDetail={true}
        detailRowAutoHeight={true}
        detailCellRendererParams={detailCellRendererParams}
        // 행 선택을 허용한다.
        onSelectionChanged={(e) => setSelectedRows(e.api.getSelectedRows())}
        rowSelection={'multiple'}
      />
    </div>
  );
};

export default NewUnsettledInvoiceTable;
