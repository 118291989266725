import { DeliveryTaskDoc, PartnersUserDoc } from '@gooduncles/gu-app-schema';

type RowData = DeliveryTaskDoc & {
  partnersUser: PartnersUserDoc | null;
};

const AgCellPartnerName = (params: any) => {
  const value: string = params.value;
  const data = params.data as RowData;
  const color = data.partnersUser?.deliveryPartnerStatus.color ?? 'black';
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ backgroundColor: color, width: '4px', height: '14px', marginRight: '4px' }} />
      <div>{value}</div>
    </div>
  );
};

export default AgCellPartnerName;
