import { FileExcelOutlined } from '@ant-design/icons';
import { CommerceConf, OrderStatusCode } from '@gooduncles/gu-app-schema';
import { Button, Tooltip, message, notification } from 'antd';
import { uniq } from 'lodash-es';
import { FC, useCallback, useState } from 'react';

import { formatDate } from 'src/lib/1/date-util';
import { getDeliveredAt } from 'src/lib/1/util';
import { SupplierDoc } from 'src/lib/3/schema-supplier';
import { getStoreIssues } from 'src/lib/4/firebase-short-cut';
import { ConsoleLogger } from 'src/lib/5/logger';
import { OrderWithStore, downloadOrderProductsToExcel, storeIssueToRawData } from 'src/lib/5/order-util';

const logger = ConsoleLogger.getInstance();
const logName = '주문 관리';

type RawDataDownloadButtonProps = {
  orders: OrderWithStore[];
  selectedStatus: number[];
  commerceConf: CommerceConf | undefined;
  suppliers: SupplierDoc[] | undefined;
  disabled: boolean;
};

const RawDataDownloadButton: FC<RawDataDownloadButtonProps> = ({
  orders,
  selectedStatus,
  commerceConf,
  suppliers,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const downloadRawDataExcel = useCallback(async () => {
    if (!suppliers) {
      message.error('suppliers를 가져오지 못했습니다. 개발자에게 알려주세요.');
      return;
    }

    if (!commerceConf) {
      message.error('commerceConf를 가져오지 못했습니다. 개발자에게 알려주세요.');
      return;
    }

    // 배송 완료 주문의 관련 이슈를 가져오기 위함.
    const deliveredAtList = uniq(
      orders
        .filter((order) => order.deliveredAt)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((order) => formatDate(order.deliveredAt!, "yyyy-MM-dd'T'00:00:00+0900"))
    );
    const notFinishedOrders = orders.filter((order) =>
      [OrderStatusCode.ACCEPTED, OrderStatusCode.SUBMITTED].includes(order.orderStatus)
    );
    const willBeDeliveredAtList = uniq(notFinishedOrders.map((order) => formatDate(order.orderDate, 'yyyy-MM-dd'))).map(
      (d) => {
        const weekday = new Date(d).getDay();
        const deliveredAt = getDeliveredAt(commerceConf?.deliveryDateRules[weekday], new Date(d));
        return formatDate(deliveredAt, "yyyy-MM-dd'T'00:00:00+0900");
      }
    );

    setLoading(true);
    try {
      const issueDates = [...deliveredAtList, ...willBeDeliveredAtList];
      const issues = ((await getStoreIssues([['date', 'in', issueDates]])) ?? [])
        .filter((issue) => issue.isDeleted !== true)
        .filter((issue) => ['완료', '입력'].includes(issue.status ?? ''));
      const issueRawData = storeIssueToRawData(issues);
      const rawDataLength = await downloadOrderProductsToExcel(
        orders,
        issueRawData,
        {
          statusList: selectedStatus,
          deliveryDateRules: commerceConf.deliveryDateRules,
        },
        suppliers
      );
      logger.logConsole(`${logName} - 주문내역[${selectedStatus.join(', ')}] ${rawDataLength}개 다운로드 완료`);
    } catch (error) {
      console.error(error);
      if (error instanceof TypeError) {
        const reasons = error.cause as string[];
        notification.error({
          message: error.message,
          description: `오류 원인 목록: ${reasons.join(', ')}\n사용자 관리에서 식당코드를 입력해주세요.`,
          style: { whiteSpace: 'pre-line' },
          duration: 0,
        });
      } else {
        console.error(error);
        logger.logConsole(`${logName} - 주문내역[${selectedStatus.join(', ')}] 다운로드 과정에서 에러 발생.`, {
          level: 'error',
        });
        message.error('주문내역을 다운로드 과정에서 알 수 없는 에러가 발생했습니다. 개발자에게 알려주세요.');
      }
    }
    setLoading(false);
  }, [commerceConf, orders, selectedStatus, suppliers]);

  return (
    <Tooltip title='한 번에 내려받을 수 있는 기간은 최대 7일입니다.'>
      <Button icon={<FileExcelOutlined />} onClick={downloadRawDataExcel} loading={loading} disabled={disabled}>
        raw data 내려받기
      </Button>
    </Tooltip>
  );
};

export default RawDataDownloadButton;
