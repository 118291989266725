import { DeliveryTaskDoc } from '@gooduncles/gu-app-schema';
import { groupBy } from 'lodash-es';
import { FC, useEffect, useState } from 'react';

import { observeDeliveryTask } from 'src/lib/4/firebase-short-cut';

import { selectPartnersUsers } from 'src/redux/slices/partners';
import { useAppSelector } from 'src/redux/store';

import DailyCheckListForPartner from 'src/atomic-components/molecules/DailyCheckListForPartner/DailyCheckListForPartner';
import Loading from 'src/components/Loading/Loading';

import classes from './DailyCheckListBoard.module.scss';

interface DailyCheckListBoardProps {
  date: string;
}

type PartnersDeliveryTasksGroup = {
  sortKey: number;
  partnerName: string;
  deliveryTaskList: DeliveryTaskDoc[];
};

const DailyCheckListBoard: FC<DailyCheckListBoardProps> = ({ date }) => {
  const partnersUsers = useAppSelector(selectPartnersUsers);
  const [loading, setLoading] = useState(false);
  const [groupList, setGroupList] = useState<PartnersDeliveryTasksGroup[]>([]);

  useEffect(() => {
    if (!date || partnersUsers.length === 0) return;
    setLoading(true);
    const subscription = observeDeliveryTask([['date', '==', date]]).subscribe((docs) => {
      setLoading(false);
      const groupByPartnerId = groupBy(docs, 'partnerId');
      const groupList: PartnersDeliveryTasksGroup[] = Object.entries(groupByPartnerId).map(
        ([partnerId, deliveryTaskList]) => {
          const partner = partnersUsers.find((user) => user._id === partnerId);
          if (!partner) return { partnerName: '?', sortKey: 999, deliveryTaskList };
          return { partnerName: partner.name, sortKey: partner.deliveryPartnerStatus.sortKey, deliveryTaskList };
        }
      );
      setGroupList(groupList.sort((a, b) => a.sortKey - b.sortKey));
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [date, partnersUsers]);

  return (
    <section className={classes.boardContainer}>
      {loading && <Loading title='잠시만 기다려주세요.' />}
      {groupList.length === 0 && !loading && <div>No data</div>}
      {groupList.map((group) => (
        <DailyCheckListForPartner
          key={group.partnerName + group.sortKey}
          partnerName={group.partnerName}
          deliveryTaskList={group.deliveryTaskList}
        />
      ))}
    </section>
  );
};

export default DailyCheckListBoard;
