import { Checkbox, Popover, message, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Timestamp } from 'firebase/firestore';
import { partition } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DndCardItem, DndColumnData } from 'src/schema/schema-drag-and-drop';
import { TaskSpot, confirmDeliveryRoute, createDeliveryRouteHistoryData } from 'src/utils/partners-util';
import { separateTaskSpotToOrder } from 'src/utils/task-util';

import { LocalStorageKey } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { errorObjectToString, removeLocalStorageValue, setLocalStorageValue } from 'src/lib/1/util';
import { updatePartnersUser } from 'src/lib/4/firebase-short-cut';

import { selectPartnersUsers } from 'src/redux/slices/partners';
import { useAppSelector } from 'src/redux/store';

import useCommerceConf from 'src/hooks/useCommerceConf';
import useTaskSpots from 'src/hooks/useTaskSpots';

import DeliveryColumnHeader from 'src/atomic-components/molecules/DeliveryColumnHeader/DeliveryColumnHeader';
import DropdownCheckList from 'src/atomic-components/molecules/DropdownCheckList/DropdownCheckList';
import TaskSpotCard from 'src/atomic-components/molecules/TaskSpotCard/TaskSpotCard';
import Loading from 'src/components/Loading/Loading';

import classes from './DeliveryRouteBoard.module.scss';

import KanbanBoard from '../KanbanBoard/KanbanBoard';

interface DeliveryRouteBoardProps {
  date: string;
}

/**
 * GU Partners 배차 업무 보드
 * : 자사의 물류 서비스인 GU Partners의 배차를 결정하는 Kanban 스타일 보드
 */
const DeliveryRouteBoard: FC<DeliveryRouteBoardProps> = ({ date }) => {
  const { getDeliveryDateForNow } = useCommerceConf();
  const [isDateValid, setIsDateValid] = useState<boolean>(true);
  const [preview, setPreview] = useState<boolean>(false);
  const partners = useAppSelector(selectPartnersUsers);
  const sortedPartners = useMemo(
    () => [...partners].sort((a, b) => a.deliveryPartnerStatus.sortKey - b.deliveryPartnerStatus.sortKey),
    [partners]
  );
  const partnersCheckList = sortedPartners.map((partner) => ({
    label: partner.nickname,
    key: partner._id,
    checked: partner.deliveryPartnerStatus.isActive,
  }));

  const { loading, taskSpots, isRouteConfirmed, mutateTaskSpots } = useTaskSpots(date, preview);
  const [valid, invalid] = partition(taskSpots, (taskSpot) => taskSpot.deliverySpot);
  const invalidStores = invalid.flatMap((spot) => spot.stores.flatMap((ts) => ts.store?.storeNickname));
  const rowData = useMemo(
    () =>
      valid
        .map((taskSpot) => {
          const backgroundColor =
            partners.find((p) => p._id === taskSpot.spotDefaultPartnerId)?.deliveryPartnerStatus.color ?? '#ffffff';
          const isPartnerActive = partners.find((p) => p._id === taskSpot.partnerId)?.deliveryPartnerStatus.isActive;
          const cardItem: DndCardItem = {
            id: taskSpot.deliverySpotId,
            groupId: isPartnerActive ? taskSpot.partnerId : 'undefined',
            title: taskSpot.deliverySpot?.title ?? 'unregistered',
            index: taskSpot.deliverySpot?.sortKey ?? 999,
            data: taskSpot,
            cardStyle: { backgroundColor },
            element: (
              <TaskSpotCard title={taskSpot.deliverySpot?.title ?? 'unregistered'} data={taskSpot} showDetail={false} />
            ),
            detailElement: (
              <TaskSpotCard title={taskSpot.deliverySpot?.title ?? 'unregistered'} data={taskSpot} showDetail={true} />
            ),
          };
          return cardItem;
        })
        .sort((a, b) => a.index - b.index),
    [partners, valid]
  );
  const partnerColumns = useMemo(
    () => [
      ...sortedPartners
        .filter((partner) => partner.deliveryPartnerStatus.isActive)
        .map((partner) => ({
          id: partner._id,
          title: partner.nickname,
        })),
      { id: 'undefined', title: '미할당', pinRight: true },
    ],
    [sortedPartners]
  );

  const onCheckBoxChange = useCallback(async (e: CheckboxChangeEvent, id: string) => {
    try {
      await updatePartnersUser(id, { ['deliveryPartnerStatus.isActive']: e.target.checked });
      message.success('배송 파트너 상태 변경');
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '배송 파트너 상태 변경 실패',
        description,
      });
    }
  }, []);

  /**
   * 배차가 확정되면 호출되는 콜백
   */
  const onItemsChange = useCallback(
    async (items: DndCardItem[], columns: DndColumnData[]) => {
      try {
        await confirmDeliveryRoute(date, items, columns);
        mutateTaskSpots();
      } catch (error) {
        console.error(error);
        const errorMessage = errorObjectToString(error);
        notification.error({
          message: '배차 확정에 실패했습니다.',
          description: errorMessage,
        });
      }
    },
    [date, mutateTaskSpots]
  );

  /**
   * 배차가 임시 저장되면 호출되는 콜백
   */
  const onItemsChangeTemporary = useCallback(
    async (items: DndCardItem[]) => {
      try {
        const deliveryRouteHistory = createDeliveryRouteHistoryData(date, items);
        setLocalStorageValue(LocalStorageKey.temporaryDeliveryRoute, {
          ...deliveryRouteHistory,
          _timeUpdate: formatDate(Timestamp.now().toDate(), "yyyy-MM-dd'T'HH:mm:ss+0900"),
        });
        notification.success({
          message: '배차 임시 저장 완료',
          description: `${date} 배차가 임시 저장되었습니다.`,
        });
      } catch (error) {
        console.error(error);
        const errorMessage = errorObjectToString(error);
        notification.error({
          message: '배차 임시 저장에 실패했습니다.',
          description: errorMessage,
        });
      }
    },
    [date]
  );

  /**
   * 임시 저장된 배차를 삭제하는 콜백
   */
  const clearItemsChangeTemporary = useCallback(() => {
    removeLocalStorageValue(LocalStorageKey.temporaryDeliveryRoute);
    notification.success({
      message: '임시 저장된 내용을 삭제했습니다.',
      description: '새로고침합니다.',
    });
    location.reload();
  }, []);

  const headerMaker = useCallback((column: DndColumnData, items: DndCardItem[]) => {
    const groupItems = items.filter((item) => item.groupId === column.id);
    const groupSummary = groupItems.reduce(
      (summary, cur) => {
        const taskSpot = cur.data as TaskSpot;
        const storeCount = taskSpot.stores.length;
        const { orders, storeIssues } = separateTaskSpotToOrder(taskSpot);
        const orderAmount = orders.reduce((acc, cur) => acc + (cur.totalAmount ?? 0), 0);
        const storeIssueAmount = storeIssues.reduce(
          (acc, cur) => acc + ((cur.supplyPrice ?? 0) + (cur.tax ?? 0)) * (cur.volume ?? 1),
          0
        );
        return {
          spotCount: summary.spotCount,
          storeCount: summary.storeCount + storeCount,
          totalAmount: summary.totalAmount + orderAmount + storeIssueAmount,
        };
      },
      {
        spotCount: groupItems.length,
        storeCount: 0,
        totalAmount: 0,
      }
    );
    return <DeliveryColumnHeader {...groupSummary} />;
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const currentDate = getDeliveryDateForNow();
      if (currentDate) {
        const boardDay = new Date(date).getDate();
        const currentDay = new Date(currentDate).getDate();
        const isValid = boardDay === currentDay;
        if (!isValid) {
          message.warning('보드의 날짜가 현재 날짜와 다릅니다. 새로고침 해주세요.');
        }
        setIsDateValid(isValid);
      }
    }, 5000);
    return () => clearInterval(timer);
  }, [date, getDeliveryDateForNow]);

  return (
    <div className={classes.routeBoardContainer}>
      <div className={classes.routeBoardHeader}>
        <h3>{date}</h3>
        <div className={classes.options}>
          <Checkbox checked={preview} onChange={(e) => setPreview?.(e.target.checked)}>
            주문 미리보기
          </Checkbox>
          <DropdownCheckList title='배송 파트너' checkList={partnersCheckList} onChange={onCheckBoxChange} />
        </div>
      </div>
      {invalidStores.length > 0 && (
        <Popover title='미등록 매장' content={invalidStores.join(', ')}>
          <span className={classes.invalidItems}>
            ⚠️ {`${invalidStores.length}개의 배송지 미등록 매장이 있습니다.`}
          </span>
        </Popover>
      )}
      {loading && <Loading title='데이터를 불러오고 있습니다.' />}
      <KanbanBoard
        columns={partnerColumns}
        rowData={rowData}
        onItemsChangeTemporary={onItemsChangeTemporary}
        onClearItemsChangeTemporary={clearItemsChangeTemporary}
        disableItemsChangeTemporary={isRouteConfirmed}
        onItemsChange={onItemsChange}
        disableItemsChange={preview || !isDateValid}
        headerMaker={headerMaker}
      />
    </div>
  );
};

export default DeliveryRouteBoard;
