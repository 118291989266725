import { FC } from 'react';

import usePartnersUser from 'src/hooks/usePartnersUser';

import classes from './PartnerInfoRow.module.scss';

interface PartnerInfoRowProps {
  partnerId: string | undefined;
}

const PartnerInfoRow: FC<PartnerInfoRowProps> = ({ partnerId }) => {
  const { partnersUser } = usePartnersUser(partnerId);
  const isActive = !!partnersUser?.deliveryPartnerStatus.isActive;
  return (
    <div className={classes.partnerInfoRowContainer}>
      <div className={classes.partnerColorBox} style={{ backgroundColor: partnersUser?.deliveryPartnerStatus.color }} />
      <div className={classes.partnerContent}>
        <b>
          {partnersUser?.nickname ?? '-'}
          <span className={`${classes.status} ${isActive ? classes.active : ''}`}>{isActive ? '활성' : '비활성'}</span>
        </b>
        <p>
          {partnersUser?.userTel ?? '-'} / {partnersUser?.email ?? '-'}
        </p>
      </div>
    </div>
  );
};

export default PartnerInfoRow;
