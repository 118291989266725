import { Button, notification } from 'antd';
import { FC, useCallback, useState } from 'react';

import { errorObjectToString } from 'src/lib/1/util';
import { deletePartnersVehicle, getPartnersUsers } from 'src/lib/4/firebase-short-cut';

const DeleteVehicleButton: FC = (params: any) => {
  const [loading, setLoading] = useState(false);
  const { data } = params;

  const onDeleteVehicle = useCallback(async () => {
    setLoading(true);
    try {
      const partnersUsers = await getPartnersUsers([[`deliveryPartnerStatus.vehicleId`, '==', data._id]]);
      if (partnersUsers.length > 0) {
        throw new Error('차량을 사용중인 파트너가 있습니다.');
      }
      await deletePartnersVehicle(data._id);
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '차량 삭제 실패',
        description,
      });
    }
    setLoading(false);
  }, [data._id]);

  if (!data?._id) {
    return null;
  }

  return (
    <Button danger loading={loading} onClick={onDeleteVehicle}>
      삭제
    </Button>
  );
};

export default DeleteVehicleButton;
